import React, {
  createContext,
  useContext,
  useReducer,
  useMemo,
  useEffect,
} from "react"

export const GlobalContext = createContext()

const reducer = (state, pair) => ({ ...state, ...pair })

const initialState = {
  locations: [],
  selectedRestaurant: null,
  isMobile: null,
  isTablet: null,
  isDesktop: null,
  headerHeight: null,
  mapHeight: null,
  isModalOpen: false,
  selectedCategory: null,
  recipeCategory: [],
  selectedProduct: null,
  filterLocationsBy: [],
  locationToSearch: null,
  filteredRestaurants: [],
  setCenter: null,
  setLng: null,
  setLat: null,
  videoId: null,
  videoSrc: null,
  modalVideoOpen: false,
}

export const useGlobalState = () => {
  const context = useContext(GlobalContext)
  if (context === null) {
    throw new Error(
      "useGlobalState must be used within an GlobalContext Provider"
    )
  } // end if

  return context
}

export function GlobalContextProvider({ children }) {
  const [globalState, updateGlobalState] = useReducer(reducer, initialState)
  // To avoid unnecesary re-renders
  const value = useMemo(
    () => ({
      globalState,
      updateGlobalState,
    }),
    [globalState]
  )

  // Check window size and validate if is mobile
  const validateMobile = () => {
    // CHECK SIZE FOR WINDOW
    if (window.innerWidth >= 1024) {
      updateGlobalState({
        isMobile: false,
        isTablet: false,
        isDesktop: true,
      })
    } else if (window.innerWidth >= 768) {
      updateGlobalState({
        isMobile: false,
        isTablet: true,
        isDesktop: false,
      })
    } else {
      updateGlobalState({
        isMobile: true,
        isTablet: false,
        isDesktop: false,
      })
    }
  }

  useEffect(() => {
    const resizeWindow = () => {
      validateMobile()
    }
    validateMobile()
    window.addEventListener("resize", resizeWindow)

    return () => {
      updateGlobalState({
        isMobile: false,
        isTablet: false,
        isDesktop: false,
      })
      window.removeEventListener("resize", resizeWindow)
    }
  }, [])

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  )
}

export const useGlobalContext = () => useContext(GlobalContext)

export default GlobalContextProvider